<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br>
    <br />
    <v-card class="card">
      <v-card-title class="heading justify-center">
        <span>Semester</span>
      </v-card-title>
      <div v-if="init_loading">
        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-card-text>
          <div class="add-section">
            <div style="text-align: center">
              <v-col>
                <v-form ref="form">
                  <label class="add-text">Add New Semester</label><span style="color: red;"> *</span>
                  <v-text-field class="text" v-model="year" outlined dense :rules="[rules.required]" autocomplete="off"
                    clearable required></v-text-field>
                  <label class="add-text">Display Name</label><span style="color: red;"> *</span>
                  <v-text-field class="text" v-model="display_name" outlined dense :rules="[rules.required]"
                    autocomplete="off" clearable required></v-text-field>

                  <v-btn color="success" @click="addyear">Add</v-btn>
                </v-form>
              </v-col>
            </div>
          </div>

          <template>
            <div class="text-center">
              <v-dialog v-model="dialog" width="500">
                <v-card>
                  <v-card-title class="grey lighten-2"> Edit Semester </v-card-title>

                  <v-card-text style="padding-bottom: 0px">
                    <div class="add-section">
                      <div style="text-align: center">
                        <v-col>
                          <v-form ref="form">
                            <label class="add-text">Add New Semester</label><span style="color: red;"> *</span>
                            <v-text-field class="text" v-model="edityear" outlined dense :rules="[rules.required]"
                              autocomplete="off" clearable required></v-text-field>
                            <label class="add-text">Display Name</label><span style="color: red;"> *</span>
                            <v-text-field class="text" v-model="editdisplay_name" outlined dense :rules="[rules.required]"
                              autocomplete="off" clearable required></v-text-field>
                          </v-form>
                        </v-col>
                      </div>
                    </div>
                  </v-card-text>

                  <!-- <v-divider></v-divider> -->

                  <div class="text-center">
                    <v-spacer></v-spacer>
                    <v-btn class="m-3" @click="dialog = false"> Close </v-btn>
                    <v-btn class="m-3 green white--text" @click="saveEdit()">Update</v-btn>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </template>
          <div v-if="loader">
            <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
          </div>
          <div v-else>
            <template v-if="yeardata != null">
              <v-data-table :headers="headers" :items="yeardata" :search="search" class="elevation-1 nowrap">
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                      hide-details></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:item.edit="{ item }">
                  <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                  <v-icon v-if="item" class="mr-2" @click="updateyear(item)">mdi mdi-pencil-box</v-icon>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-icon v-if="item" class="mr-2" @click="deleteyear(item.id)">
                    mdi mdi-delete</v-icon>
                </template>
              </v-data-table>
            </template>
          </div>
        </v-card-text>
      </div>
    </v-card>
    <!-- <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
  </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    init_loading: false,
    loader: false,
    snackbar_msg: "",
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Admin Master',
        disabled: false,
        href: '/admin-master',
      },
      {
        text: 'Semester',
        disabled: true,
        href: '',
      },
    ],
    snackbar: false,
    color: "",
    org: null,
    dialog: false,
    search: "",
    year: null,
    display_name: null,
    edityear: null,
    editdisplay_name: null,
    yearid: null,
    programName: null,
    programtypeName: null,
    userType: null,
    edit_btn_load: false,
    rules: {
      required: (value) => !!value || "Required.",
    },

    yeardata: null,
    headers: [
      { text: "Sr no", value: "srno" },
      { text: "Year", value: "year" },
      { text: "Display", value: "display_name" },
      { text: "Edit", value: "edit" },
      { text: "Delete", value: "delete" },
      // { text: "Toggle", value: "actions", sortable: false },
    ],
  }),

  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    editItem(item) {
      console.log(item);
      axios
        .post("/admin/currencyIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Shift updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    updateyear(item) {
      this.dialog = true;
      this.yearid = item.id;
      this.edityear = item.year;
      this.editdisplay_name = item.display_name;
    },

    saveEdit() {
      if (this.edityear) {
        this.edityear = this.edityear.trim();
      }
      if (this.editdisplay_name) {
        this.editdisplay_name = this.editdisplay_name.trim();
      }

      const data = {
        yearid: this.yearid,
        year: this.edityear,
        display_name: this.editdisplay_name,
      };

      if (this.edityear && this.editdisplay_name) {
        this.loader = true;
        axios
          .post("/admin/editsemester", data)
          .then((res) => {
            if (res.data.status == "success") {
              this.loader = false;
              this.showSnackbar("#4CAF50", "Semester updated successfully...");
              this.edityear = "";
              this.editdisplay_name = "";

              this.dialog = false;
              this.onload();
            } else {
              this.showSnackbar("#b71c1c", res.data.status);
            }
          })
          .catch((error) => {
            this.loader = false;
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.showSnackbar("#b71c1c", "Please enter Semester  ");
        this.$refs.form.validate();
      }
    },
    deleteyear(item) {
      const data = {
        yearid: item,
      };
      axios
        .post("/admin/deletesemester", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar("#4CAF50", "Semester Deleted Successfully  !!");
            this.onload();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          window.console.log(error);
        });
    },

    onload() {
      this.init_loading = true;
      axios.post("/admin/getsemesterdata").then((res) => {
        this.init_loading = false;
        if (res.data.msg == "200") {
          this.yeardata = res.data.yeardata;
        } else {
          this.init_loading = false;
        }
      });
    },

    addyear() {
      if (this.year == null) {
        this.showSnackbar("#b71c1c", "Please Enter Semester ");
        return;
      }
      if (this.display_name == null) {
        this.showSnackbar("#b71c1c", "Please Enter Display Name ");
        return;
      }

      const data = {

        year: this.year,
        display_name: this.display_name,
      };


      this.loader = true;
      axios
        .post("/admin/savesemester", data)
        .then((res) => {
          if (res.data.status == "success") {
            this.loader = false;
            this.showSnackbar("#4CAF50", "Semester Added successfully...");
            this.year = "";
            this.display_name = "";
            this.onload();
          } else {
            this.showSnackbar("#b71c1c", res.data.status);
          }
        })
        .catch((error) => {
          this.loader = false;
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });

    },
  },
  mounted() {
    this.onload();
  },
};
</script>
<style scoped>
.heading {
  background-color: #3f51b5;
  padding: 0.3rem;
  color: white;
}

.v-icon {
  cursor: pointer;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
